@import "../../variables";

.main-nav {
  align-content: center;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: space-between;
  width: 100%;
  z-index: 1000;

  @media (max-width: 1024px) {
    .dropdown-menu[data-bs-popper] .dropdown-item {
      color: $Gray-Light2;
      font-family: "Inter";
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 19px;
      margin-top: 10px;
      text-transform: uppercase;
    }
    .connect-button-wrapper {
      width: 100%;
    }

    .connect-button-wrapper .connect-button {
      width: 100%;
    }
    .dropdown-toggle.nav-link {
      align-items: center;
      display: flex;
    }
    a[aria-expanded="true"] {
      .dropdown-toggle::after {
        transform: rotate(270deg);
      }
    }

    .show > .dropdown-toggle.nav-link {
      align-items: center;
      color: $primary-text !important;
      display: flex;
    }
  }

  .show > .dropdown-toggle.nav-link {
    color: $primary-text !important;
  }

  .dropdown-menu.show {
    background: $purchase-card-background;
    display: block;
    @media (max-width: 1024px) {
      background: transparent;
      border: 0;
    }
  }

  .dropdown-item {
    color: $primary-text;
  }

  .dropdown-item:hover {
    background: $purchase-card-background;
    color: $primary-text;
  }

  .gold-background {
    // not img
    :not(img) {
      background: $connect-color !important;
      color: $primary-text;
      cursor: pointer;
    }

    .wallet-icon-button {
      background: transparent !important;
    }
  }
}

.nav-connect-end-wrapper {
  align-content: center;
  align-items: center;
  /* flex-wrap: nowrap; */
  /* flex-direction: row; */
  display: flex !important;
  gap: 32px;
  justify-content: center;
  @media (max-width: 1024px) {
    align-items: start;
  }
}

.nav-connect-button-wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  @media (min-width: 1024px) {
    align-items: flex-end;
  }
  @media (max-width: 1024px) {
    /* text-align: center; */
    /* align-items: center; */
    flex-direction: column-reverse;
    gap: 1rem;
    /* row-gap: 1rem; */
    width: 100%;
  }
}

.bull-pen-brand {
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 1.5em;
  height: 100%;
  justify-content: center;
  margin-inline-end: 12px;
  text-align: center;
  transition: all 0.2s ease-in-out;
  width: 100%;

  &:hover {
    opacity: 0.6;
  }

  .bull-pen-text {
    color: $primary-text;

    &:first-letter {
      font-weight: bold;
    }
  }

  .bull-pen-subtext {
    color: $sub-text;
    font-size: 0.5em;
  }
}

.active {
  //border-bottom: 2px solid $connect-color;
}

.wallet-icon {
  align-items: center;
  background: $gray-dark;
  border-radius: 30px;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 50px;
  /* display: none;*/
  @media (max-width: 1024px) {
    display: none;
  }
}

.my-wallet-btn {
  display: none;
  @media (max-width: 1024px) {
    display: block;
  }
}

#responsive-navbar-nav {
  @media (max-width: 1024px) {
    align-content: center;
    align-items: center;
    flex-direction: row;
    justify-content: start;
    padding-bottom: 20px;
    row-gap: 20px;
    text-align: center;
    width: 100%;
    &.collapsing {
      opacity: 0;
    }
    &.show,
    &.collapsing {
      display: flex;
      height: calc(100vh - 100px);
      transition: none;

      .nav-connect-end-wrapper {
        flex-direction: column;
        justify-content: center;
      }
    }
    .nav-container {
      font-family: "Inter";
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 19px;
      margin: 0;
      //display: flex;
      max-width: 100% !important;
      text-transform: uppercase;
    }
  }

  .container {
    &.navbar-nav {
      @media (max-width: 1024px) {
        display: flex;
        flex-direction: column;
      }
    }
  }

  @media (max-width: 1024px) {
    flex-direction: column-reverse;
  }
}

.navbar-logo {
  height: 54px;
  margin-inline: 8px;
}

.disabled {
  opacity: 0.25;
}

.wallet-icon-button {
  background: $gray-dark !important;
  color: $primary-text !important;
  cursor: pointer;
  max-height: 44px;
  max-width: 44px;
  transition: all 0.2s ease-in-out;
  // hover if not disabled
  &:hover:not(.disabled) {
    opacity: 0.6;
  }
}

.btn.focus,
.btn:focus {
  box-shadow: none !important;
  outline: 0;
}

.dropdown-item:focus {
  background: rgba(0, 0, 0, 0.12);
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none !important;
  outline: 0;
}

.dropdown-menu[data-bs-popper] {
  margin: 0;

  .dropdown-item {
    color: $primary-text;
  }
}

@media (max-width: 1024px) {
  .navbar-expand-lg .navbar-nav {
    flex-direction: column;
  }
}

.nav-item {
  &.dropdown {
    &.show {
      a {
        &::after {
          @media (max-width: 1024px) {
            transform: rotate(-90deg);
          }
        }
      }
    }
  }
}

.active-dropdown {
  .dropdown-toggle {
    color: $primary-text !important;
  }
}

.social-media-icons {
  display: none;
  @media (max-width: 1024px) {
    bottom: 20px;
    display: block;
    height: 50px;
    position: absolute;
    width: 100%;
    img {
      margin: 12px;
    }
  }
}
