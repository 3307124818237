@import '../../../variables';

.back-navigation {
  display: flex;

  justify-content: space-between;

  .backButton {
    align-items: center;
    background: transparent;
    color: $Gray-Light2;

    display: flex;
    font-family: 'Inter';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;

    line-height: 36px;
    text-align: center;
    text-transform: none;
    white-space: nowrap;

    &:hover {
      background: $white-shade !important;
      color: $black-shade !important;
    }
  }
}

.purchase {
  background: $page-background;
  color: $primary-text;
  display: grid;
  grid-template-columns: 2fr;
  grid-template-rows: 1fr;

  .no-background,
  &.no-background {
    background: none;
    border: 0;
  }

  .mobile-display {
    //display: none;

    @media screen and (max-width: 991px) {
      display: block;
    }

    .coin-details-section {
      display: none;

      @media screen and (max-width: 991px) {
        display: block;
      }
    }
  }

  .desktop-display {
    //display: block;

    @media screen and (max-width: 991px) {
      display: none;
    }

    .coin-details-section {
      display: block;

      @media screen and (max-width: 991px) {
        display: none;
      }
    }
  }

  .only-outline {
    background: none;
    border: 1px solid $gray-dark;
  }

  .logo-card {
    border-radius: $purchase-card-border-radius;

    // background: $purchase-card-background;
    height: 450px;
    //margin-block: $margin-block;
    width: 100%;

    &.disabled {
      opacity: 0.25;
    }

    .purchase-card-logo {
      border-radius: $purchase-card-border-radius;
      height: 100%;
      width: 100%;
      background: $gray-dark6;
    }

    .purchase-card-logo-loading {
      align-items: center;
      background: $gray-dark6;
      display: flex;
      height: 100%;
      justify-content: center;
      width: 100%;
      border-radius: $purchase-card-border-radius;
    }
  }

  .silver-bar-logo {
    background-position: center;
    background-size: cover;
    outline: 2px solid $Gray-Light3;
  }

  .gold-bar-logo {
    background-position: center;
    background-size: cover;
    outline: 2px solid $connect-color;
  }

  &-card {
    background-position: center;
    background: $purchase-card-background;
    border: 1px solid $gray-dark;

    border-radius: $purchase-card-border-radius;
    margin-block: $margin-block;
    padding: $purchase-card-padding;

    &.disabled {
      opacity: 0.25;
    }

    .metadata-type-text {
      font-family: 'Inter';
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      letter-spacing: 0.15px;
      line-height: 28px;
      text-transform: uppercase;
    }

    .token-metadata-title {
      font-family: 'Inter';
      font-size: 30px;
      font-style: normal;
      font-weight: 800;
      letter-spacing: 0.25px;
      line-height: 36px;
    }

    .token-metadata-mintLocation {
      color: $Gray-Light2;
      font-family: 'Inter';
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      letter-spacing: 0.5px;
      line-height: 24px;
    }

    &-item {
      background-color: $purchase-card-background;
      color: $primary-text;

      &-title {
        border-bottom: 1px solid $sub-text;
        display: flex;
        justify-content: space-between;
        padding-block: 0.5em;

        > div {
          display: flex;

          > div {
            font-family: 'Inter';
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            letter-spacing: 0.25px;
            /* line-height: 32px; */
            margin-left: 0.5rem;
          }
        }
      }

      &-metadata {
        align-content: space-between;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        //flex-wrap: wrap;
        margin-block: 0.5em;
        margin-top: 18px;
        overflow: auto;
        transition: all 0.2s ease-in-out;

        // fontsize should be slightly smaller on mobile
        @media (max-width: 991px) {
          font-size: 0.8rem;
        }

        &-item {
          align-content: space-between;
          display: flex;
          flex-direction: row;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          justify-content: space-between;
          line-height: 16px;
          margin-block: 0.5em;

          &-key {
            color: $sub-text;
            font-family: 'Inter';
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 17px;
            margin-bottom: 14px;
          }

          &-value {
            color: $primary-text;
            font-family: 'Inter';
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 17px;
            text-align: right;

            @media screen and (max-width: 600px) {
              max-width: 70%;
              word-break: break-word;
            }
          }
        }
      }
    }
  }

  // space between each element

  &-footer {
    background: $purchase-card-background;
    border-radius: $purchase-card-border-radius;
    margin-block: $margin-block;
    padding: $purchase-card-padding;

    &-qty {
    }

    &-payment {
      &-logo {
      }

      &-text {
      }
    }
  }
  .wallet-badge-wrapper {
    position: absolute;
    border-left: 130px solid $Prime-yellow;
    border-bottom: 130px solid transparent;
    .wallet-icon-badge {
      position: absolute;
      margin-left: -115px;
      right: auto;
      background: $Prime-yellow !important;
      margin-top: 12px;
      height: 50px !important;
      width: 50px !important;
    }
  }
}

.purchase-section {
  column-gap: 1em;
  align-content: center;
  align-items: flex-start;
  background: $purchase-card-background;
  border-radius: 12px;
  display: inline-flex;
  flex-direction: column;
  justify-content: space-around;
  vertical-align: middle;
  &.listed {
    background: $purchase-card-background;
  }

  .current-price-text {
    display: flex;
    width: 100%;
    font-family: 'Inter';
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.25px;
    line-height: 40px;
    justify-content: space-between;
  }

  .current-coin-details {
    display: flex;

    @media screen and (max-width: 991px) {
      display: inline-block;
    }
  }

  .token-paymentType-value {
    font-weight: 600;
  }

  &-qty {
    /* text/body-2 */

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.25px;
    line-height: 20px;
    white-space: nowrap;
  }

  &-payment-logo {
    align-self: center;
    margin-inline-end: 8px;
  }
}

.purchase-buttons {
  column-gap: 1em;
  width: 100%;
  margin-top:1em;
  display: flex;
  min-height: 50px;
  justify-content: space-between;
  //flex-wrap: wrap;

  button {
    height: 100%;
  }


  @media screen and (min-width: 992px) and (max-width: 1399px) {
    //display: inline-block;
  }

  .change-price {
    display: flex;
    margin-left: 2rem;

    @media screen and (max-width: 767px) {
      margin-left: 0;
    }

    @media screen and (min-width: 992px) and (max-width: 1399px) {
      margin-left: 0;
    }


    &-input {
      border: 1px solid #bec5ca;
      margin: 0 1rem 0 0;
      padding: 0.5rem;
      height: 100%;
      width: 10rem;
      background-color: #242627;
      border-radius: 4px;
      color: #fff;
    }
  }

  .collection-buttons {
    align-items: center;
    background-color: $Prime-yellow;
    color: $white-shade;
    display: flex;
    font-family: 'Inter';

    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 36px;
    text-align: center;
    text-transform: none;
    white-space: nowrap;

    &:hover {
      background: $white-shade;
      color: $black-shade;
    }

    &:disabled,
    :disabled,
    &[disabled] {
      background-color: $shaded2;
      border: 1px solid $sub-text;
      color: $sub-text;
    }



    @media screen and (min-width: 992px) and (max-width: 1199px) {
      margin: 0 0 1rem 0;
    }
  }
}

.navigations-buttons {
  /* @media screen and (max-width: 512px) {
    display: inline-block;
  }
 */
  .gray-dark-background {
    background-color: $gray-dark;
  }

  .prime-yellow-background {
    background-color: $Prime-yellow;
  }

  .collection-buttons {
    align-items: center;
    border-radius: 12px;
    color: $white-shade;
    display: flex;
    font-family: 'Inter';

    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 36px;
    text-align: center;
    text-transform: none;
    white-space: nowrap;

    &:hover {
      background: $white-shade;
      color: $black-shade;
    }
    margin-block: 0.8rem;

    @media screen and (min-width: 992px) and (max-width: 1199px) {
      margin: 0 0 1rem 0;
    }

    .left-arrow {
      height: 16px;
      left: 15px;
      position: absolute;
      right: auto;
      width: auto;

      @media screen and (max-width: 500px) {
        display: none;
      }
    }

    .right-arrow {
      height: 16px;
      left: auto;
      position: absolute;
      right: 15px;
      width: auto;

      @media screen and (max-width: 500px) {
        display: none;
      }
    }
  }

  .arrow-btns {
    display: none;

    @media screen and (max-width: 500px) {
      display: block;
    }
  }
}

button.add-to-wishlist {
  /* Auto layout */
  @include general-font;

  background: $Gray-Light1;

  border-color: $Gray-Light1;
  border-radius: 4px;

  flex-direction: row;
  font-size: 14px;

  /* L Gray-Light1 */

  font-weight: 500;
  gap: 16px;

  height: 44px;

  padding: 0 16px;

  text-align: center;
  width: 175px;
  //line-height: 36px;
  /* identical to box height, or 257% */

  &:hover {
    background: $Gray-Light1;
    border-color: $page-background;
    color: $page-background;
  }
}

.purchase-history-chart {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.btn-primary:disabled,
.btn-primary.disabled {
  background: revert;
  border-color: $primary-text;
  color: $primary-text;
  opacity: 0.5;
}
