@import "../../../variables";

.collection-modal {
  background-color: $purchase-card-background;

  .collection-modal-header {
    border-bottom: 0;
    margin: 1rem;

    .collection-modal-title {
      margin: auto 0 auto auto;

      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 30px;
      line-height: 36px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.25px;
      color: $primary-text;

      @media screen and (max-width: 490px) {
        font-size: 24px;
      }
    }
  }

  .collection-modal-body {
    padding: 0;

    .collection-table-container {
      min-width: 500;
      background-color: $page-background;

      .collection-table-header {
        background-color: $gray-dark;
        border-bottom: 2px solid $table-border-bottom;

        & th {
          color: $Gray-Light3;
          padding: 4px 16px 2px 16px;
          font-weight: 700;
          font-size: 12px;
          letter-spacing: 0.4px;
        }

        .sticky-column {
          position: sticky;
          right: 0;
          background: $gray-dark;
        }
      }
    }

    .collection-table-body {
      background-color: $page-background;

      .Mui-selected {
        background-color: $table-item-hover;
        box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.12);

        .sticky-column {
          background-color: $table-item-hover !important;
          box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.12);
        }

        .Mui-checked {
          color: $yellow2;
        }
      }

      .collection-table-body-row {
        border-bottom: 2px solid $table-border-bottom;

        &tr {
          &:hover {
            td {
              background-color: $table-item-hover !important;
            }
          }
        }

        & td {
          color: $primary-text;
          font-size: 14px;
          font-weight: 400px;
          letter-spacing: 0.25px;
          line-height: 20px;
          white-space: nowrap;

          &:last-child {
            background-color: $page-background;
          }
        }

        &:hover {
          background-color: $table-item-hover !important;
          box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.12);

          td {
            background-color: $table-item-hover !important;
            box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.12);
          }
        }

        .sticky-column {
          position: sticky;
          right: 0;
        }
      }

      .collection-table-noData {
        border-bottom: 2px solid $page-background;

        & td {
          text-align: center;
          color: $primary-text;
        }
      }

      .collection-table-body-adjust {
        border-bottom: 2px solid $page-background;
      }
    }

    .collection-table-footer {
      border-bottom: 2px solid $page-background;
      color: $primary-text;

      .table-pagination {
        color: $primary-text;

        .MuiTablePagination-selectIcon {
          color: $primary-text;
        }

        .MuiInputBase-root {
          border-bottom: 2px solid $Gray-Light1;
        }

        .MuiTablePagination-actions {
          .Mui-disabled {
            color: $Gray-Light1 !important;
          }
        }

        .MuiTablePagination-selectLabel,
        .MuiTablePagination-displayedRows {
          padding-top: 1rem;
          color: $primary-text;
        }
      }
    }
  }

  .collection-modal-footer {
    border-top: 0;
    margin: 1rem;

    @media screen and (max-width: 990px) {
      margin: 1rem auto;
      // display: block;
    }

    .collection-buttons {
      margin: 0 1rem;
      padding: 1rem 2rem;

      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 36px;

      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 1.25px;

      &:hover {
        color: $black-shade;
        background: $white-shade;
      }

      @media screen and (max-width: 990px) {
        margin-bottom: 1rem;
        width: 100%;
      }
    }

    .Mui-disabled {
      background-color: $Prime-Dark2;
      padding: 1rem 2rem;
      // font-family: 'Rubik';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 36px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 1.25px;
      color: rgba(0, 0, 0, 0.58);
    }
  }
}
