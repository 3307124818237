@import "../../../variables";

.purchase-home {
  margin: 4rem auto 10rem auto;
  color: $primary-text;

  .primary-text {
    @include general-font;
    font-weight: 800;
    //letter-spacing: 1.5px;
    text-align: center;
    flex-direction: column;
    //width: min(539px, 100%);
    //margin: auto;
    //font-weight: 800;
    font-size: calc(2.2rem + 1.1vw);
    //max-width: 100%;

    .explore-and-collect {
      //white-space: normal;
    }

    .silver-and-gold {
      display: flex;
      white-space: break-spaces;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      //align-items: unset;
      //white-space: break-spaces;
    }
  }

  .spotlight-cards-container {
    display: flex;
    justify-content: center;
    gap: 4rem;
    margin: 4rem auto;

    @media screen and (max-width: 590px) {
      flex-direction: column;
      align-items: center;
    }
  }
}
