@import "../../../../variables";

.sales-end-timer-container {
  margin-inline: 0.5rem;
  padding-inline: 0.5rem;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.25px;
  padding-right: 2rem;
  color: $Gray-Light2;
  width: 215px;
  text-align: left;
}

.sales-end-timer {
  text-align: center;
}

.sale-expired {
  float: right;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.25px;
  color: $Red;
}
