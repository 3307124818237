.whitelist {
  color: #f5f5f5;
  &-enabled {
    background: green;
  }
  &-disabled {
    background: red;
  }
  &-loading {
    background: orange;
  }
}
