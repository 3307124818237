@import "../../../../variables";

.price-history-card {
  background: $purchase-card-background;
  border-radius: 1rem;
  margin-block: 1rem;
  padding: 1rem 1.5rem;

  .price-history-heading {
    display: flex;
    justify-content: space-between;

    color: $primary-text;

    .price-history-title-text {
      font-size: 18px;
      font-weight: 700;
      letter-spacing: 0.25px;
      line-height: 32px;
      font-style: normal;
      font-family: "Inter";
    }
  }

  .price-history-graph {
    margin-block: 0.5em;

    fieldset {
      legend {
        width: unset;
      }
    }

    .MuiSelect-icon {
      color: $Gray-Light3;
    }

    .MuiSelect-select {
      border: 2px solid $Gray-Light3;
      color: $Gray-Light3;
      width: 200px;
    }

    .duration-list {
      display: flex;
      flex-direction: row;
      list-style: none;
      padding: 0;
      background-color: rgb(29, 31, 32);

      .duration-item {
        margin: 0.5rem auto;
        cursor: pointer;
        color: gray;
      }

      .active-duration {
        color: #fff;
      }
    }
  }
}

.MuiMenu-list {
  background-color: $page-background;
  color: $Gray-Light3;

  li:hover {
    background-color: lighten($color: $page-background, $amount: 10);
  }
}