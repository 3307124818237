@import "../_variables.scss";

.browse-filter {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 8px;
  margin-inline-end: 16px;

  /* gb-dark2 */

  border: 1px solid $browser-nav-border-color;
  border-radius: 4px;
}
.navbar-nav {
  :hover {
    //border-color: $connect-color !important;
    //color: $connect-color !important;
    .browse-filter {
      border-color: $connect-color !important;
      color: $connect-color !important;
    }
  }
}
.show > .dropdown-toggle.nav-link {
  color: $connect-color !important;
}

.browser-page-navbar {
  //max-height: 60px;
  border: 1px solid $browser-nav-border-color;
  border-radius: 12px;
  margin-block: 24px;
  z-index: 10;
  background: $Gray-Light1;

  .nav-link {
    &:after {
      display: none !important;
    }
  }

  /* gb-light2 */
}

.NFT-card {
  text-decoration: none;
  /* gb-light2 */
  height: 455px;
  width: 100%;
  border-radius: 12px;

  // center everything
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  color: $primary-text;
  background: $gray-dark;
  padding: 0.5em;

  //&:hover {
  //  border: 1px solid $connect-color;
  //  color: $connect-color;
  //}
}
.NFT-card-image {
  height: 248px;
  width: 248px;
  border-radius: 0;
}

.NFT-card-title {
  font-family: inter, serif;

  font-size: 18px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0.25px;
  text-align: center;
}
.NFT-card-subtitle {
  //styleName: text/subtitle-2;
  font-family: inter, serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.10000000149011612px;
  text-align: center;
}
.NFT-card-price {
  font-family: rubik, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0.25px;
  text-align: center;
}

.NFT-card-price-wrapper {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  vertical-align: middle;
  * {
    align-self: center;
  }
}
.NFT-card-price-logo {
  width: 25px;
  height: 25px;
  align-self: normal;
}
.NFT-card-qty {
  /* 2 Available */
  /* text/caption */

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  /* identical to box height, or 167% */
  text-align: center;
  letter-spacing: 0.4px;
  /* L Gray-Light1 */
  color: $sub-text;
}
.disabled {
  color: $sub-text;
  opacity: 0.5;
  cursor: not-allowed !important;
}
