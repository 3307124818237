@import "./_variables.scss";
@import "../node_modules/bootstrap/scss/bootstrap";
@font-face {
  font-family: Roboto;
  src: url("resources/fonts/Roboto-Regular.ttf");
}
body,
html {
  background-color: $page-background;
  margin: 0;
  font-family: Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 100vw !important;
  width: 100%;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  overflow-y: scroll;
}

img,
Link,
.card,
a {
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
