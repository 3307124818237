@import "../../variables";

//.balance-widget {
//  border: 1px solid $browser-nav-border-color;
//  border-top-color: #212529;
//  background: #212529;
//  padding: 12px;
//  display: flex;
//  flex-direction: column;
//  height: 100%;
//  justify-content: space-between;
//  // ease in
//  > span {
//    width: 100%;
//    text-align: center;
//    transition: all 0.2s ease-in-out;
//    > strong {
//      width: 50%;
//      //color:$primary-text;
//    }
//    > i {
//      width: 100%;
//      //color:$primary-text;
//    }
//  }
//}

.balance-text-secondary {
  @include general-font;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  letter-spacing: 0.25px;

  /* L Gray-Light2 */

  color: $Gray-Light2;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}

.balance-text-primary {
  @include general-font;

  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  letter-spacing: 0.5px;

  color: $primary-text;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}
.radius-top {
  border-radius: 12px 12px 0px 0px !important;
}
.radius-bottom {
  border-radius: 0px 0px 12px 12px;
}

.balance-address-text {
  color: white;
}

.wallet-balance-header {
  @include general-font;

  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  letter-spacing: 0.5px;

  /* shades/white */

  color: $primary-text;

  font-family: "inter", sans-serif;
  font-style: normal;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.disabled {
  .MuiListItem-root {
    opacity: 0.5;
  }
}
