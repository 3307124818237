@import "../../../variables";

$ribbon-bg-start: #DFA926;
$ribbon-bg-end: #DFA926;
$ribbon-color: #fff;
.box {
  position: relative;
  background: transparent !important;
  height: 100%;
  width: 100%;
}
.ribbon {
  position: absolute;
  left: -5px;
  top: -5px;
  z-index: 1;
  overflow: hidden;
  width: 75px;
  height: 75px;
  text-align: right;
}

.ribbon span {
  font-size: 10px;
  font-weight: bold;
  color: $ribbon-color;
  text-transform: uppercase;
  text-align: center;
  line-height: 20px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);

  width: 100px;
  display: block;
  background: $gray-dark;
  background: linear-gradient($ribbon-bg-start 0%, $ribbon-bg-end 100%);
  box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
  position: absolute;
  top: 19px;
  left: -21px;
}

.ribbon span::before {
  content: "";
  position: absolute;
  left: 0px;
  top: 100%;
  z-index: -1;
  border-left: 3px solid $ribbon-bg-start;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid $ribbon-bg-start;
}

.ribbon.right {
  left: unset;
  right: -5px;
  text-align: right;
  span {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    left: unset;
    right: -21px;
    ::after {
      right: 0px;
    }
  }
}
