@import "../../../variables";

.button-frame {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .button {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 24px;
    //color: #ffffff;
    //border: 1px solid #ffffff;
    border-radius: 20px !important;

    .button-content {
      display: flex;
      flex-direction: row;
      align-items: center;

      flex: none;
      order: 0;
      .spotlight-card-button-content-text {
        font-family: rubik, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;

        display: flex;
        flex-direction: row;

        text-align: center;
      }
    }
  }
}
