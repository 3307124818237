@import "../../variables";

.unAuth-page {
  margin: 5rem auto 10rem auto;
  text-align: center;

  .heading-text {
    font-family: "Inter";
    font-style: normal;
    font-weight: 800;
    font-size: 78px;
    line-height: 96px;
    letter-spacing: 1.5px;
    color: $Gray-Light1;

    @media screen and (max-width: 768px) {
      font-size: 38px;
    }
  }

  .heading-sub-text {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 96px;
    letter-spacing: 1.5px;
    color: $Gray-Light1;

    @media screen and (max-width: 768px) {
      font-size: 38px;
    }
  }

  .connection-info {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 36px;
    letter-spacing: 0.25px;
    color: $Gray-Light3;

    @media screen and (max-width: 768px) {
      font-size: 18px;
      line-height: 22px;
    }
  }
}
