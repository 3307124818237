@import "../../variables";

.connectModalBody {
  align-items: stretch;
  display: grid;
  grid-gap: 6rem;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  z-index: 100;
}

.custom-toast {
  //background-color: $page-background;
  //border-color: $connect-color;
  top: 80px;
  width: 450px;
}

.connectModal {
  z-index: 10000;
  // if there is only one modal, it will be centered

  .connect-header {
    display: flex;
    margin-top: 1.5rem;

    .title {
      color: $primary-text;
      display: flex;
      display: flex;
      font-family: "Inter";
      font-size: 30px;
      font-style: normal;
      font-weight: 700;
      justify-content: center;
      letter-spacing: 0.25px;
      line-height: 36px;

      width: 100%;
    }

    .close-button {
      bottom: auto;
      left: auto;
      position: absolute;
      right: 15px;
      top: 20px;
    }

    .btn-close {
      opacity: 1;
    }
  }

  .connect-btn-img {
    max-width: 100%;
    @media (max-width: 574px) {
      max-width: 80%;
    }
  }

  .small-img {
    img {
      margin-top: -20px;
    }
  }

  .content-header {
    align-items: center;
    color: $primary-text;
    display: flex;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    /* identical to box height, or 150% */

    letter-spacing: 0.5px;
    line-height: 24px;
    margin: 1.5rem auto;
    text-align: center;

    text-align: center;
  }

  div.modal-footer {
    //invert the border-radius
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
  }

  div.modal-content {
    background: $page-background;
    color: $background;
    height: auto;
    padding: 10px;
    width: 102%;
    //border-radius: 25px;
  }
}

.connector-button-wrapper {
  background: $gray-dark;
  border: 0.5px solid $gray-dark;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  //height: 189px;
  justify-content: center;
  padding: 3.5rem;
  text-align: center;

  button {
    &:hover {
      background-color: transparent;
    }
  }

  @media (max-width: 574px) {
    padding: 0;
  }

  .button-label {
    color: $primary-text;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    /* or 150% */
    letter-spacing: 0.5px;

    line-height: 24px;
  }
}

.connector-button {
  /* display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  color: $background;
  border: 1px solid $background; */

  &:hover {
    border: 0.5px solid $connect-color;
    color: $connect-color;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.1;
  }
}

.connect-button-wrapper {
  /* Auto layout */

  align-items: flex-start;
  display: flex;
  flex: none;
  flex-direction: row;
  flex-grow: 0;
  gap: 10px;

  height: 64px;
  justify-content: space-between;

  /* Inside auto layout */

  order: 2;
  padding: 10px;
  width: 246px;
  z-index: 2;

  .connect-button {
    /* Button */

    align-items: center;
    background: transparent;
    border: 1px solid $connect-color;
    border-radius: 4px;

    /* Auto layout */

    box-sizing: border-box;
    color: $connect-color;
    display: flex;

    flex: none;
    flex-direction: row;
    flex-grow: 0;

    gap: 16px;

    /* Primary Yellow */

    height: 44px;

    /* Inside auto layout */

    justify-content: space-evenly;
    order: 0;
    padding: 0px 25px;

    &.active {
      background: $gray-dark;
      border: 1px solid transparent;
      color: $white-shade;
    }

    .connected {
      background: $gray-dark;
      border: 0;
      color: $primary-text;
    }

    .connect-button-content {
      /* Auto layout */

      align-items: center;
      align-self: stretch;
      display: flex;
      // no wrap
      flex: none;
      flex-direction: row;
      flex-grow: 0;

      gap: 8px;

      /* Inside auto layout */

      height: 44px;
      order: 0;
      padding: 11px 0px;
      white-space: nowrap;
    }
  }
}

.connected-account {
  position: absolute;
}

.custom-toast-container {
  top: 85px !important;

  z-index: 10000000 !important;

  @media (max-width: 991px) {
    bottom: 0 !important;
    top: unset !important;
    z-index: 100000 !important;
  }
}
