@import "../../variables";

.categories-row {
  //display: flex;
  //flex-direction: row;
  //align-items: flex-start;

  /* Inside auto layout */

  //flex: none;
  //order: 1;
  //flex-grow: 0;
}

.category-card {
  display: flex;
  flex-direction: column;
  text-decoration: none !important;

  .category-image {
    border-radius: 18px 18px 0 0;
    background: $primary-text;

    height: 298px;

    width: 100%;
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
  .category-label {
    @include general-font;

    /* text/h5 */
    border-radius: 0 0 18px 18px;
    background: $Gray-Light1;
    /* identical to box height, or 145% */

    /* gb-dark2 */
    color: $primary-text;

    padding: 24px;

    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    /* identical to box height, or 160% */

    letter-spacing: 0.25px;
    text-transform: uppercase;
  }
}
