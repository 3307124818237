$Grey: #9e9e9e;
$Gray-Light1: #66696a;
$Gray-Light2: #a3a6a9;
$Gray-Light3: #bec5ca;
$Gray-Light4: #f5f5f5;
$Gray-Light5: #fafafa;
$Gray-Light6: #767676;

$gray-dark: #323435;
$gray-dark6: #252526;
$gray-dark3: #424242;

$table-border-bottom: #1c1b1b;
$table-item-hover: #464433;

$shaded2: #a3a6a9;
$I-Gray: #3c3d3e;
$background: #ffffff;
$browser-nav-border-color: #a3a6a9;
$sub-text: #66696a;
$primary-text: #ffffff;
$page-background: #1d1f20;
$connect-color: #dda824;
$lode-blue: #36a9fd;
$light-blue: #3cbee1;
$purchase-card-background: #242627;
$purchase-card-border-radius: 1rem;
$purchase-card-padding: 24px;
$margin-block: 1rem;

$Red: #e64041;

$black-shade: #000000;
$white-shade: #ffffff;

$Prime-Dark2: #926c0f;

$Prime-yellow: #dfa926;
$yellow2: #faa810;

:root {
  --primary: #36a9fd;

  --secondary: #22D2A0;
  --background: $page-background;
  --green: #1FC11B;
  --yellow: #FFD913;
  --prime-yellow: #dfa926;
  --red: #e64041;

  --orange: #FF9C55;
}

.Gray-Light1 {
  color: $Gray-Light1;
}

.Gray-Light2 {
  color: $Gray-Light2;
}

.Gray-Light3 {
  color: $Gray-Light3;
}

.silver-text {
  color: $Grey;
}

.gold-text {
  color: $connect-color;
}

.lode-blue-text {
  color: $lode-blue;
}

.avax-text {
  color: $Red;
}

.page-background {
  background-color: $page-background;
}

.gray-dark-background {
  background: $gray-dark;
}

.cursor-pointer {
  cursor: pointer;
}

@mixin general-font {
  font-family: "inter", sans-serif;
  font-style: normal;
  display: flex;
  align-items: center;
}

@mixin general-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 24px;
  gap: 16px;
  text-decoration: none;
}

.body-1 {
  @include general-font;
  align-items: center;
  display: flex;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.5px;
  line-height: 24px;
}

.text {
  @include general-font;
  color: $primary-text;
  font-size: 30px;
  letter-spacing: 0.25px;
  line-height: 36px;

  &.primary {
    font-weight: 700;
  }

  &.secondary {
    font-weight: 400;
  }

  &.sub-text {
    font-size: 14px;
    //line-height: 24px;
    //letter-spacing: 1.25px;
  }
}

@mixin general-text {
  width: 87px;
  height: 36px;

  font-family: rubik, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 36px;
  /* identical to box height, or 257% */

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 1.25px;

  /* shades/white */

  color: $primary-text;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}
