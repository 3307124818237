@import "../../../../variables";

.event-history-card {
  background: $purchase-card-background;
  border-radius: 1rem;
  margin-block: 1rem;
  padding: 1rem 0;

  .event-history-heading {
    display: flex;
    justify-content: space-between;
    padding: 0px 18px 0px 18px;
    color: $primary-text;

    .event-history-title-text {
      font-size: 18px;
      font-weight: 700;
      letter-spacing: 0.25px;
      line-height: 32px;
      font-style: normal;
      font-family: "Inter";
    }
  }

  .event-history-table {
    margin-block: 0.5em;

    // fontsize should be slightly smaller on mobile
    @media (max-width: 991px) {
      font-size: 0.8rem;
    }

    .table-action-items {
      display: flex;
      margin-left: 28px;
      margin-bottom: 18px;
      color: $primary-text;

      .icons-text {
        letter-spacing: 1.25px;
        line-height: 36px;
        font-weight: 600;
        font-size: 14px;
        margin-right: 36px;
      }
    }

    .eventTable-container {
      min-width: 500;
      background-color: $page-background;

      .eventTable-header {
        background-color: $gray-dark;
        border-bottom: 2px solid $table-border-bottom;

        & th {
          color: $Gray-Light3;
          padding: 4px 16px 2px 16px;
          font-weight: 700;
          font-size: 12px;
          letter-spacing: 0.4px;
        }
      }
    }

    .eventTable-body {
      border-bottom: 2px solid $table-border-bottom;

      & td {
        color: $primary-text;
        font-size: 14px;
        font-weight: 400px;
        letter-spacing: 0.25px;
        line-height: 20px;
        white-space: nowrap;
      }

      &:hover {
        background: $gray-dark;
      }

      .event-activity-text {
        text-transform: capitalize;
      }
    }

    .eventTable-noData {
      border-bottom: 2px solid $page-background;

      & td {
        text-align: center;
        color: $primary-text;
      }
    }

    .eventTable-tableAdjust {
      border-bottom: 2px solid $page-background;
    }

    .event-table-footer {
      border-bottom: 2px solid $page-background;
      color: $primary-text;

      .table-pagination {
        color: $primary-text;

        .MuiTablePagination-selectIcon {
          color: $primary-text;
        }

        .MuiInputBase-root {
          border-bottom: 2px solid $Gray-Light1;
        }

        .MuiTablePagination-actions {
          .Mui-disabled {
            color: $Gray-Light1 !important;
          }
        }

        .MuiTablePagination-selectLabel,
        .MuiTablePagination-displayedRows {
          padding-top: 1rem;
          color: $primary-text;
        }
      }
    }
  }

  // small screens center the text
  @media (max-width: 991px) {
    text-align: center;
  }
}
