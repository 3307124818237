@import "../../variables";

.browse-page {
  > div {
    // is Row
    margin-block: 4em;
  }

  div {
    @media (max-width: 768px) {
      justify-content: center;
      text-align: center;
    }
  }
}

.browse-row {
  .browse-col {
    margin-block: $margin-block;

    :hover {
      //border-color: $connect-color !important;
      //color: $connect-color !important;
      border-color: $connect-color !important;
      color: $connect-color !important;
    }
  }
}
