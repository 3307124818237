@import "../../variables";

.nft-table {
  margin-block: 0.5em;

  // fontsize should be slightly smaller on mobile
  @media (max-width: 991px) {
    font-size: 0.8rem;
  }
  tr {
    td {
      border-bottom: none !important;
    }
    th {
      border-bottom: none !important;
    }
  }
  .nft-table-wrapper {
    border-radius: 10px;
  }

  .table-action-items {
    display: flex;
    margin-left: 28px;
    margin-bottom: 18px;
    color: $primary-text;

    .icons-text {
      letter-spacing: 1.25px;
      line-height: 36px;
      font-weight: 600;
      font-size: 14px;
      margin-right: 36px;
    }
  }

  .nftTable-container {
    //min-width: 500;
    background-color: $purchase-card-background;
    border-radius: 10px;

    .nftTable-header {
      background-color: $gray-dark;
      border-bottom: 1px solid $gray-dark;

      & th {
        color: $Gray-Light3;
        padding: 4px 16px 2px 16px;
        font-weight: 700;
        font-size: 12px;
        letter-spacing: 0.4px;
      }
    }
  }

  .nftTable-body {
    border-bottom: 1px solid $page-background;
    & td {
      color: $primary-text;
      font-size: 14px;
      font-weight: 400px;
      letter-spacing: 0.25px;
      line-height: 20px;
      white-space: nowrap;
    }

    &:hover {
      background: $gray-dark;
    }

    .event-activity-text {
      text-transform: capitalize;
    }
  }

  .NFTtable-noData {
    border-bottom: 2px solid $page-background;

    & td {
      text-align: center;
      color: $primary-text;
    }
  }

  .NFTtable-tableAdjust {
    border-bottom: 2px solid $page-background;
  }

  .nft-table-footer {
    border-bottom: 2px solid $page-background;
    color: $primary-text;

    .table-pagination {
      color: $primary-text;

      .MuiTablePagination-selectIcon {
        color: $primary-text;
      }

      .MuiInputBase-root {
        border-bottom: 2px solid $Gray-Light1;
      }

      .MuiTablePagination-actions {
        .Mui-disabled {
          color: $Gray-Light1 !important;
        }
      }

      .MuiTablePagination-selectLabel,
      .MuiTablePagination-displayedRows {
        padding-top: 1rem;
        color: $primary-text;
      }
    }
  }
  .silver-tag {
    width: 52px;
    height: 32px;

    /* Silver Background */

    background: url("../../resources/img/silver_background.png");
    border-radius: 16px;
    background-size: 100%;
    color: $gray-dark;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .golden-tag {
    width: 53px;
    height: 32px;

    /* Gold Background */

    background: url("../../resources/img/gold_background.png");
    border-radius: 16px;
    background-size: 101%;
    color: $gray-dark;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
