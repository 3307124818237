// div with attribute role="tooltip" is a tooltip

.info-tooltip {
  max-width: 500px;
}

.info-popper {
  max-width: 500px;
}

.info-values {
  padding-inline-end: 10px;
  background-color: transparent !important;
  display: flex !important;
  justify-content: space-between !important;
  border-bottom: 1px solid #e0e0e0 !important;

  padding-block: 0.5rem !important;
  flex-wrap: wrap !important;

  .check-icon {
    color: #00c853;
  }
  .close-icon {
    color: #d50000;
  }
}
.list {
  max-height: 200px;
  overflow-y: auto;
}
