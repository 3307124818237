@import "../../../../variables";

.listing-modal {
  background-color: $purchase-card-background;
  color: $primary-text;

  .listing-modal-headerText {
    align-items: center;
    color: $white-shade;
    display: flex;
    font-family: "Inter";
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.25px;
    line-height: 36px;

    text-align: center;
  }

  .listing-modal-body {
    padding: 3rem;

    @media screen and (max-width: 575px) {
      padding: 1rem;
      padding-bottom: 3rem;
    }

    .listing-modal-input-body {
      .list-amount-label {
        align-items: center;
        color: $white-shade;
        display: flex;
        font-family: "Inter";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        letter-spacing: 0.5px;

        line-height: 24px;
      }

      .list-balance-label {
        align-items: center;
        color: $Gray-Light2;
        display: flex;
        float: right;
        font-family: "Inter";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        letter-spacing: 0.1px;
        line-height: 15px;

        text-align: right;
      }

      .input-style {
        background-color: $purchase-card-background;
        border: 1px solid $Gray-Light3;
        border-radius: 4px;
        color: $white-shade;
        height: 4rem;

        &:focus {
          box-shadow: none;
        }
      }

      .form-control:disabled {
        background-color: lighten(
          $color: $purchase-card-background,
          $amount: 5
        ) !important;
      }
    }

    .listing-modal-submission-body {
      .selected-items-details {
        border-bottom: 1px solid $page-background;
        display: flex;
        justify-content: space-between;
        padding-bottom: 1rem;

        .asset-logo {
          @media screen and (max-width: 991px) {
            display: none;
          }
        }

        .silver-bar-logo {
          background: url("../../../../resources/img/silver_background.png");
          background-position: center;
          border-radius: 10px;
        }

        .gold-bar-logo {
          background: url("../../../../resources/img/gold_background.png");
          background-position: center;
          border-radius: 10px;
        }

        h5 {
          color: $white-shade;
          font-family: "Inter";
          font-size: 18px;
          font-style: normal;
          font-weight: 800;
          letter-spacing: 0.25px;
          line-height: 22px;

          @media screen and (max-width: 590px) {
            font-size: 15px;
          }
        }

        .listing-price-text {
          color: $primary-text;
          font-family: "Inter";
          font-size: 18px;
          letter-spacing: 0.25px;
          text-transform: uppercase;

          @media screen and (max-width: 590px) {
            font-size: 15px;
          }
        }

        .listing-selected-items-labels {
          color: #afafaf;
          font-family: "Inter";
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          letter-spacing: 0.15px;
          line-height: 15px;
          margin: 0.5rem 0;
          text-transform: uppercase;

          @media screen and (max-width: 590px) {
            font-size: 12px;
          }
        }
      }

      .approve-title {
        color: $white-shade;
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 800;
        letter-spacing: 0.25px;
        line-height: 22px;
      }

      .approve-text {
        color: $white-shade;
        font-family: "Inter";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        letter-spacing: 0.5px;
        line-height: 24px;
      }
    }
  }

  .listing-modal-footer {
    border-top: none;
    display: flex;
    justify-content: space-between;
    padding: 0 3rem 2rem 3rem;

    @media screen and (max-width: 575px) {
      padding: 1rem;
      padding-bottom: 3rem;
    }

    .listing-modal-footer-buttons {
      font-family: "Inter";
      font-size: 14px;
      letter-spacing: 1.25px;
      line-height: 36px;
      white-space: nowrap;
      width: 45%;

      &:hover {
        background: $white-shade;
        color: $black-shade;
      }

      &:disabled {
        background: $Gray-Light6;
        color: $white-shade;
        cursor: not-allowed !important;
      }

      @media screen and (max-width: 575px) {
        font-size: 10px;
      }
    }

    .listing-modal-warning-buttons {
      font-family: "Inter";
      font-size: 14px;
      height: 52px;
      letter-spacing: 1.25px;
      line-height: 36px;
      white-space: nowrap;
      //width: 45%;
      @media screen and (max-width: 575px) {
        font-size: 10px;
      }
    }
  }

  .react-datepicker {
    background-color: $page-background !important;
    color: $primary-text;
  }

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    color: $primary-text;
  }

  .react-datepicker__header {
    background-color: $page-background !important;
  }

  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    color: $primary-text;
  }

  .react-datepicker__day--selected {
    background-color: $connect-color !important;
  }

  .react-datepicker__day:hover {
    background-color: lighten($color: $Gray-Light1, $amount: 20);
  }

  .react-datepicker__day--disabled {
    color: #767676;
  }

  .react-datepicker__input-time-container
    .react-datepicker-time__input-container
    .react-datepicker-time__input
    input {
    border-color: $primary-text;
    /* background-color: #1d1f20; */
    /* border: 0; */
    /* color: #ffffff; */
    border-radius: 4px;
    border-width: 1px;
    padding: 3px;
    width: auto;
  }

  .form-control:disabled {
    color: #ffffff5e !important;
  }

  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    background-color: $connect-color !important;
  }
}
