@import "../../variables";

.vault-status {
  .drp-dwn-label {
    width: 28px;
    height: 20px;

    /* text/body-2 */

    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    display: flex;
    align-items: center;
    letter-spacing: 0.25px;

    /* L Gray-Light2 */

    color: $Gray-Light2;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .MuiMenu-list {
    background-color: $page-background;
    color: $Gray-Light3;
  }

  li:hover {
    background-color: lighten($color: $page-background, $amount: 10);
  }
  .MuiSelect-icon {
    color: $Gray-Light3;
  }

  .MuiSelect-select {
    border: 2px solid $Gray-Light3;
    color: $Gray-Light3;
    width: 100px;
  }

  .vault-card {
    background: $purchase-card-background;
    width: 100%;
    border-radius: 12px;
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
  .content-1 {
    width: 123px;
    height: 32px;

    /* text/h5 */

    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 32px;
    /* identical to box height, or 145% */

    align-items: center;
    text-align: center;

    color: $primary-text;
  }
  .content-2 {
    width: 204px;
    height: 36px;

    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
    align-items: center;
    text-align: center;
    letter-spacing: 0.25px;

    color: $primary-text;
  }
  .content-3 {
    width: 204px;
    height: 36px;

    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 36px;
    align-items: center;
    text-align: center;
    letter-spacing: 0.25px;

    color: $primary-text;
  }
  .content-4 {
    width: 207px;
    height: 36px;

    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
    align-items: center;
    text-align: center;
    letter-spacing: 0.25px;

    color: $primary-text;
  }

  .vault-card-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    hr {
      background: $white-shade;
      border: 1px solid $gray-dark;
      width: 80%;
      opacity: 1;
    }
  }
  .table-title {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
    letter-spacing: 0.25px;

    color: $primary-text;
  }
  .table-tag {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 28px;
    /* identical to box height, or 187% */

    display: flex;
    align-items: center;
    letter-spacing: 0.15px;

    /* L Gray-Light2 */

    color: $Gray-Light2;
  }
  @media (max-width: 1000px) {
    .content-1 {
      font-size: 15px;
    }

    .content-2 {
      font-size: 20px;
    }
    .content-3 {
      font-size: 15px;
    }
    .content-4 {
      font-size: 20px;
    }
  }
}
