@import "../../variables";

.page-not-found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50vh;
  margin-block: 4em;
  background-color: transparent;

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: $primary-text;
    font-size: 1.5em;
    font-weight: 600;
    margin-bottom: 1em;
    &__title {
      font-size: 2em;
      font-weight: 700;
    }
    &__subtitle {
      font-size: 1.5em;
      font-weight: 600;
    }
    &__description {
      font-size: 1em;
      font-weight: 400;
    }
  }
  &__button {
    background-color: $primary-text;
    color: $black-shade;

    &:hover {
      background-color: $black-shade;
      color: $primary-text;
    }
  }
}
