@import "../../../variables";

.redeem-token-modal {
  background-color: $purchase-card-background;

  .redeem-token-modal-headerText {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.25px;

    @media screen and (max-width: 500px) {
      font-size: 20px;
    }
  }

  &-lode-connect {
    .text-contents {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      letter-spacing: 0.5px;
    }

    .download-text {
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 22px;
      line-height: 32px;
      text-align: center;
    }

    .store-buttons-list {
      display: flex;
      justify-content: center;

      @media screen and (max-width: 991px) {
        display: block;
      }

      .access_browser1 {
        margin-left: 1.5rem;

        @media screen and (max-width: 991px) {
          margin-left: 0;
          margin-top: 2rem;
        }

        @media screen and (max-width: 768px) {
          display: none;
        }

        .btn-primary {
          width: 236px;
          height: 45px;
          background: $Gray-Light2;
          box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2),
            0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);
          border-radius: 4px;
          border-color: $Gray-Light2 !important;
          color: black;
        }
      }
    }
  }

  .redeem-token-modal-body {
    padding: 1rem 3rem;

    @media screen and (max-width: 491px) {
      padding: 1rem;
    }

    .redeem-token-modal-completion {
      .redeem-token-modal-selected-items {
        .selected-items-details {
          display: flex;
          justify-content: space-between;

          .asset-logo {
            @media screen and (max-width: 991px) {
              display: none;
            }
          }

          .silver-bar-logo {
            background: url("../../../resources/img/silver_background.png");
            background-position: center;
            border-radius: 10px;
          }

          .gold-bar-logo {
            background: url("../../../resources/img/gold_background.png");
            background-position: center;
            border-radius: 10px;
          }

          h5 {
            font-family: "Inter";
            font-style: normal;
            font-weight: 800;
            font-size: 18px;
            line-height: 22px;
            letter-spacing: 0.25px;
            color: $white-shade;

            @media screen and (max-width: 590px) {
              font-size: 15px;
            }
          }

          .token-price-text {
            font-family: "Inter";
            font-size: 18px;
            letter-spacing: 0.25px;
            color: $primary-text;
            text-transform: uppercase;

            @media screen and (max-width: 590px) {
              font-size: 15px;
            }
          }

          .redeem-token-modal-selected-items-labels {
            font-family: "Inter";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 15px;
            letter-spacing: 0.15px;
            text-transform: uppercase;
            color: #afafaf;
            margin: 0.5rem 0;

            @media screen and (max-width: 590px) {
              font-size: 12px;
            }
          }
        }
      }

      .redeem-token-modal-redeemed-asstes {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;

        display: flex;
        align-items: center;
        letter-spacing: 0.5px;
        color: $Gray-Light3;

        // border-bottom: 1px solid #1D1F20;
      }

      .redeem-token-modal-redeemed-details {
        .redeem-token-modal-redeemed-heading {
          font-family: "Inter";
          font-weight: 700;
          letter-spacing: 0.5px;
          color: $Gray-Light3;
          margin-bottom: 1rem;
        }

        .redeemed-contents {
          display: flex;
          justify-content: space-between;
          margin-bottom: 1rem;

          @media screen and (max-width: 590px) {
            flex-direction: column;
            align-items: flex-start;
          }

          .redeemed-items-heading {
            font-family: "Inter";
            font-size: 16px;
            line-height: 24px;
            display: flex;
            flex-direction: column;
            align-items: center;
            letter-spacing: 0.5px;
            color: $Gray-Light2;

            @media screen and (max-width: 590px) {
              line-height: 10px;
            }
          }

          .redeemed-items-values {
            font-family: "Inter";
            font-size: 16px;
            line-height: 24px;
            display: flex;
            flex-direction: column;
            align-items: end;
            letter-spacing: 0.5px;
            color: $primary-text;
            list-style: none;

            @media screen and (max-width: 590px) {
              align-items: flex-start;
              padding-left: 0;
            }
          }
        }
      }

      .redeem-token-modal-redeemed-textContent {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;

        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.25px;
        color: $Gray-Light3;
        margin: 1rem 3rem;

        @media screen and (max-width: 590px) {
          margin: auto;
        }
      }
    }

    .error-text {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      /* or 143% */

      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.25px;

      /* L Gray-Light3 */

      color: $Gray-Light3;
      margin: 20px 0 20px 0;
    }
  }

  .redeem-token-modal-footer {
    border-top: 0;
    display: flex;
    justify-content: end;
    padding: 0 3rem 2rem 3rem;

    // @media screen and (max-width: 991px) {
    //   flex-direction: column-reverse;
    // }

    @media screen and (max-width: 491px) {
      padding: 0 1rem 2rem 1rem;
    }

    .redeem-token-modal-footer-buttons {
      padding: 10px 3.5rem;
      font-family: "Inter";
      font-size: 14px;
      line-height: 36px;
      letter-spacing: 1.25px;
      white-space: nowrap;

      &:hover {
        color: $black-shade;
        background: $white-shade;
      }

      @media screen and (max-width: 991px) {
        margin-bottom: 1rem;
        width: 100%;
      }
    }
  }

  .redeem-token-modal-error-footer {
    border-top: 0;
    display: flex;
    justify-content: space-between;
    padding: 0 3rem 2rem 3rem;

    @media screen and (max-width: 991px) {
      flex-direction: column-reverse;
    }

    @media screen and (max-width: 491px) {
      padding: 0 1rem 2rem 1rem;
    }

    .redeem-token-modal-error-buttons {
      font-family: "Inter";
      font-size: 14px;
      line-height: 36px;
      letter-spacing: 1.25px;
      white-space: nowrap;

      &:hover {
        color: $black-shade;
        background: $white-shade;
      }

      @media screen and (max-width: 991px) {
        margin-bottom: 1rem;
        width: 100%;
      }
    }
  }
}
