@import "../../variables";

html {
  font-family: "Inter", sans-serif;
  overflow-y: scroll !important;
  overflow-x: hidden;
}
@supports (font-variation-settings: normal) {
  html {
    font-family: "Inter var", sans-serif;
  }
}
body {
  padding-right: 0 !important;
}
.explore-nft-image {
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  width: 563px;
  height: 521px;
  background: #d9d9d9;
  border-radius: 23px;
  // on mobile
  @media (max-width: 768px) {
    width: 100%;
    height: 100%;
    margin-top: 10px;
  }
}

.explore-nft-row {
  margin-block-start: 8em;
  margin-block-end: 8em;
  @media (max-width: 991px) {
    margin-block-start: 8em;
    margin-block-end: 4em;
  }
  color: $primary-text;
  display: flex;
  flex-direction: column;
  gap: 8px;
  .primary-text {
    @include general-font;
    font-weight: 800;
    //letter-spacing: 1.5px;
    text-align: center;
    flex-direction: column;
    //width: min(539px, 100%);
    //margin: auto;
    //font-weight: 800;
    font-size: calc(2.2rem + 1.1vw);
    //max-width: 100%;

    .explore-and-collect {
      //white-space: normal;
    }
    .silver-and-gold {
      display: flex;
      white-space: break-spaces;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      //align-items: unset;
      //white-space: break-spaces;
    }
  }

  .secondary-text {
    @include general-font;

    //width: min(570px, 100%);
    margin: auto;
    /* text/h4 */
    font-weight: 400;
    //font-size: 30px;
    line-height: 36px;

    text-align: center;
    letter-spacing: 0.25px;

    /* L Gray-Light3 */

    color: $Gray-Light3;

    /* text/h4 */

    font-style: normal;
    font-size: 28px;
    display: flex;
    align-items: center;

    /* L Gray-Light3 */

    color: $Gray-Light3;
  }

  .primary-button {
    @include general-font;
    @include general-button;
    /* Auto layout */
    width: 91px;
    height: 44px;
    background: $connect-color;
    color: $page-background;
    border: 1px solid transparent;

    border-radius: 4px;
    font-weight: 500;
    font-size: 14px;
    line-height: 36px;
    text-align: center;
    letter-spacing: 1.25px;

    &:hover {
      background: $page-background;
      color: $connect-color;
      border: 1px solid $connect-color;
    }
  }

  .secondary-button {
    @include general-font;
    @include general-button;

    background: transparent;
    color: $primary-text;

    display: flex;
    flex-direction: row;

    border: 1px solid $Gray-Light2;
    border-radius: 4px;
  }

  // on mobile add more margin to the top
  @media (max-width: 991px) {
    //margin-top: 15rem !important;
  }

  .explore-nft-col-img {
    text-align: center;
  }
}

.home-page {
  margin-top: 16px;
  div {
    @media (max-width: 768px) {
      /* justify-content: center;
      text-align: center; */
    }
  }
}

.explore-nft-buttons {
  display: flex;
  justify-content: center;
  gap: 16px;
  //width: 279px;
  height: 56px;
  margin: auto;
  text-align: center;
}
