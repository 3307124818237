@import '../../../variables';

.spotlight-card {
  font-family: 'inter', sans-serif;
  font-style: normal;
    font-weight: normal;
  --card-bg-color:white;
  //--card-bg-color: transparent;
  //--card-bg-color-shade: #1a1a1a;
  //--card-text-color: #ffffff;
  //--card-footer-text-color: #ffffff;
  //--card-title-color: #ffffff;
  --card-border-color: black;

  &.gold-bar {
    //--card-bg-color: transparent;;
    //--card-bg-color-shade: rgba(255, 196, 0, 0.88);
    // outline text

    //--card-text-color: #1a1a1a;
    //--card-title-color: #ffffff;
    //--card-border-color: #ffffff;
  }
  &.silver-bar {
    //--card-bg-color: transparent;
    //--card-bg-color-shade: black;
    //--card-text-color: #ffffff;
    //
    //--card-title-color: #000000;
    //--card-border-color: #ffffff;
  }

  color: var(--card-text-color);

  background-color: var(--card-bg-color);
  width: 350px;
  min-height: 150px;
  margin-block: 125px;
  //border-radius: 22px !important;
  border: 1px solid var(--card-border-color);
  background: var(--card-bg-color);
  &.metal-bar-card-multiple {
    box-shadow: 10px -11px 0 -2px var(--card-bg-color), 10px -11px var(--card-border-color),
      20px -20px 0 -2px var(--card-bg-color), 20px -20px var(--card-border-color);
  }
  position: relative;
  top: 0;
  left: 0;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  font-size: 10px;

  .media-container {
    width: 100%;
    //margin-block: 1em;
    padding-block: 0.5em;
    background-color: var(--card-bg-color);
  }

  img {
    // center image
    display: block;
    margin: auto;

    //padding: 12px;

    //height: 100%;
    width: 15em;
    height: 15em;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
    border: 8px double white;
    margin-block: 1em;
  }
  .spotlight-card-body {
    //background: var(--card-bg-color);
    padding: 1px;
  }

  .spotlight-card-title {
    color: var(--card-text-color) !important;
    display: flex;
    font-size: 1.5rem;
    padding: 0.5em 1em;
    align-items: center;
    justify-content: space-between;
    background: var(--card-bg-color);

    border-block-end: 2px solid var(--card-border-color);
  }

  .spotlight-card-footer {
    background: var(--card-bg-color);
    color: var(--card-text-color);
    display: flex;
    justify-content: end;
  }

  .badge-content {
    width: 100%;
    justify-content: center;
    background: var(--card-bg-color);
    color: var(--card-text-color);
  }
  .spotlight-card-content {
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: var(--card-bg-color) !important;
    color: var(--card-text-color);
  }
  .spotlight-content-item {
    display: flex;
    //column-gap: 1rem;
    //padding-inline: 0;

    align-items: center;

    // for all items except the last one
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
}

.spotlight-card-content-list {
  margin-inline-end: -5px;
  //border to the top of the first child
  & > * {
    border-block-start: 1px solid var(--card-border-color);
    //border-inline-end: 2px double var(--card-border-color);
  }
  & > *:first-child {
    border-top: 0;
  }
  & > *:last-child {
    //border-bottom: 0;
  }
  //border-bottom: 3px double var(--card-border-color);
  max-height: 200px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 0.5rem;

  }
  &::-webkit-scrollbar-track {
    border-radius: 20px;

    margin-block:5px;
    background-color: pink;

    outline: 1px solid var(--card-border-color);
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 20px;

    //border: 1px solid var(--card-border-color);
    border-block: 0;
    background: #216ba5;
  }
  // give the background a slight tint to make it easier to read
  //background: rgba(0, 0, 0, 0.05);
}
