@import "../../../variables";

.redeem-physical-modal {
  background-color: $purchase-card-background;

  .physical-modal-header {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .modal-headerText {
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 30px;
      line-height: 36px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.25px;
      color: $white-shade;

      @media screen and (max-width: 500px) {
        font-size: 20px;
      }
    }
  }

  .physical-modal-body {
    padding: 1rem 3rem;

    @media screen and (max-width: 491px) {
      padding: 1rem;
    }

    .modal-body-textContents {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.5px;
    }

    .choose-address {
      color: $white-shade;
      border: 1px solid $Gray-Light3;
      border-radius: 8px;
      padding: 1rem;

      .use-address-heading {
        font-family: "Inter";
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 32px;
        display: flex;
        align-items: center;
        letter-spacing: 0.25px;

        color: $primary-text;
      }

      .other-address-heading {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 32px;
        display: flex;
        align-items: center;
        letter-spacing: 0.25px;
        color: $primary-text;
      }

      .add-address-text {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        display: flex;
        align-items: center;
        letter-spacing: 0.5px;
        color: $primary-text;
      }
    }

    .add-address-container {
      .input-styles {
        border: 2px solid $Gray-Light3;
        height: 50px;
        background: transparent;
        color: $Gray-Light3;
      }

      .selectBox-class {
        color: $Gray-Light3;
        border: 2px solid $Gray-Light3;
        height: 50px;
        background: $purchase-card-background
          url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='white' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
          no-repeat right 0.75rem center/8px 10px !important;
      }
    }

    .error-text {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      /* or 143% */

      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.25px;

      /* L Gray-Light3 */

      color: $Gray-Light3;
      margin: 20px 0 20px 0;
    }
  }

  .physical-modal-footer {
    border-top: 0;
    display: flex;
    justify-content: space-between;
    padding: 0 3rem 2rem 3rem;

    @media screen and (max-width: 991px) {
      flex-direction: column-reverse;
    }

    @media screen and (max-width: 491px) {
      padding: 0 1rem 2rem 1rem;
    }

    .modal-footer-buttons {
      padding: 10px 24px;

      font-family: "Inter";
      white-space: nowrap;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 36px;

      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 1.25px;

      &:hover {
        color: $black-shade;
        background: $white-shade;
      }

      @media screen and (max-width: 991px) {
        margin-bottom: 1rem;
        width: 100%;
      }
    }

    .Mui-disabled {
      background-color: $Prime-Dark2;
      color: rgba(0, 0, 0, 0.58);
    }

    .redeem-agx-modal-error-buttons {
      font-family: "Inter";
      font-size: 14px;
      line-height: 36px;
      letter-spacing: 1.25px;
      white-space: nowrap;

      &:hover {
        color: $black-shade;
        background: $white-shade;
      }

      @media screen and (max-width: 991px) {
        margin-bottom: 1rem;
        width: 100%;
      }
    }
  }
}
