@import "../../variables";

.footer-unsubcribeText {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: $Gray-Light6;
}

.subscribe-input-wrap {
  position: relative;
}

.mc__field-container {
  .subscribe-input {
    height: 72px;
    width: 100%;
    border-radius: 8px;
    color: $primary-text;
    padding: 10px 60px 10px 20px;
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
  }

  .subscribe-input {
    background: $gray-dark6;
  }

  .subscribe-input-arrow {
    position: absolute;
    right: 20px;
    top: calc((50% - 35px / 2) + 3px);
    color: $gray-dark3;
    cursor: pointer;
  }
}

@media screen and (max-width: 990px) {
  .subscribe-input-wrap {
    margin-top: 2rem;
  }
}

.subscribe-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  .subscribe-form__success {
    display: flex;
    flex-direction: column;
    align-items: center;
    .subscribe-form__success__title {
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 30px;
      line-height: 36px;
      color: $primary-text;
      margin-bottom: 1rem;
    }
    .subscribe-form__success__text {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: $Gray-Light2;
    }
    .subscribe-form__success__button {
      button {
        margin-top: 2rem;
        color: $Gray-Light6;
        padding: 10px 20px;
        border: 1px solid $primary-text;
        border-radius: 8px;
        font-family: "Inter";
        font-style: normal;
        font-weight: 700;
      }

    }
  }
}
