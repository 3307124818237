@import "../../../../variables";

.purhcase-modal {
  background-color: $purchase-card-background;
  color: $primary-text;

  .title-text {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
    letter-spacing: 0.25px;
    margin-left: auto;
    margin-right: 0;
  }

  .form-control {
    background: $page-background;
    color: $primary-text;
  }

  .img-wrapper {
    height: auto;
    width: 100px;
    margin-right: 9px;
    padding: 10px;
  }

  .title-1 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    /* identical to box height */

    letter-spacing: 0.15px;
    text-transform: uppercase;

    color: $Gray-Light2;
  }

  .title-2 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */

    letter-spacing: 0.25px;

    /* shades/white */

    color: $primary-text;
  }

  .title-3 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.5px;
    color: $Gray-Light2;
  }

  .title-4 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    letter-spacing: 0.25px;
  }

  .close-btn {
    margin-left: auto;
    margin-right: 0;

    .close-button-icon:hover {
      color: grey;
    }
  }

  button.submit-btn {
    background: $connect-color;
    border-color: $connect-color;
    color: $page-background;

    &:hover {
      border: 1px solid $gray-dark;
      color: $connect-color;
      background: $page-background;
    }

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 16px;
    gap: 16px;

    /* Primary Yellow */

    border-radius: 4px;
    margin-inline-end: 16px;
    height: 40px;
    width: 100%;
    justify-content: center;
  }

  button.cancel-btn {
    background: $page-background;
    border-color: $primary-text;
    color: $primary-text;

    &:hover {
      border: 1px solid $gray-dark;
      color: $connect-color;
      background: $page-background;
    }

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 16px;
    gap: 16px;
    border-radius: 4px;
    margin-inline-end: 16px;
    width: 100%;
    height: 40px;
    justify-content: center;
  }

  .line-height-30 {
    line-height: 30px;
  }

  .modal-poduct-details {
    margin-top: 10px;
    line-height: 30px;
  }

  .purchase-modal-footer {
    display: flex;
    justify-content: space-between;
    padding-top: 2rem;
    border-top: 1px solid $page-background;

    @media screen and (max-width: 991px) {
      flex-direction: column-reverse;
    }

    .purchase-modal-footer-buttons {
      padding: 10px 3.5rem;
      font-family: "Inter";
      font-size: 14px;
      line-height: 36px;
      letter-spacing: 1.25px;
      white-space: nowrap;

      &:hover {
        color: $black-shade;
        background: $white-shade;
      }

      @media screen and (max-width: 991px) {
        margin-bottom: 1rem;
        width: 100%;
      }
    }
  }
}
